import {
  GetRequest,
  PatchRequest,
  PostRequest,
} from '../../../../helpers/lopay_api_helper'
import { KYBSettingsDTO } from '../dtos/kyb-settings.dto'
import { KYBStatus } from '../../../FraudPrevention/kyb-threshold.type'
import { AdditionalDocumentsRequestStatus } from '../enums/additional-documents-request-status.enum'

export function getKYBStatusAndSettings(merchantId: string) {
  return GetRequest<KYBSettingsDTO>(`support/merchant/${merchantId}/kyb`)
}

export function updateKYBSettings(
  merchantId: string,
  settings: Partial<Omit<KYBSettingsDTO, 'kybStatus'>>,
): Promise<void> {
  return PatchRequest(`support/merchant/${merchantId}/kyb/settings`, settings)
}

export function updateReviewStatus(
  merchantId: string,
  underReview: boolean,
): Promise<void> {
  return PostRequest(`support/merchant/${merchantId}/kyb/review`, {
    underReview,
  })
}

export function updateKYBStatus(
  merchantId: string,
  payload: {
    kybStatus: KYBStatus
    note?: string
  },
): Promise<void> {
  return PostRequest(`support/merchant/${merchantId}/kyb/set-status`, payload)
}

export function changeAdditionalDocumentsStatus(
  merchantId: string,
  newStatus: AdditionalDocumentsRequestStatus,
): Promise<void> {
  return PostRequest(
    `support/merchant/${merchantId}/kyb/additional-documents`,
    {
      status: newStatus,
    },
  )
}
