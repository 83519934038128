import moment from 'moment'
import { Alert, Col, Row } from 'reactstrap'
import { useUpdateReviewStatusMutation } from '../../hooks/kyb-settings.hook'
import { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'

type KYBReviewProps = {
  merchantId: string
  underReview: boolean
  underReviewUpdatedAt: Date | null
  refetchFn: () => void
}

const KYBReview = (props: KYBReviewProps) => {
  const { merchantId, underReview, underReviewUpdatedAt, refetchFn } = props

  const mutation = useUpdateReviewStatusMutation(merchantId, refetchFn)

  const [confirmAlert, setConfirmAlert] = useState(false)

  const handleChangeUnderAdditionalReview = () => {
    mutation.mutate(!underReview)
  }

  const underReviewDate = moment(underReviewUpdatedAt).format(
    'YYYY/MM/DD HH:mm:ss Z',
  )

  return (
    <div>
      <Row>
        <Col sm={12}>
          <div className="form-check form-switch form-switch-lg mt-4 mb-4">
            <input
              id="under-additional-review-checkbox"
              className="form-check-input"
              type="checkbox"
              checked={underReview}
              disabled={mutation.isLoading}
              onChange={(e) => {
                if (underReview) {
                  handleChangeUnderAdditionalReview()
                } else {
                  setConfirmAlert(true)
                }
              }}
            />
            <label
              className="form-check-label"
              htmlFor="under-additional-review-checkbox"
            >
              {mutation.isLoading && (
                <i className="mdi mdi-loading mdi-spin font-size-24"></i>
              )}
              <span className="ms-1">Placed under further review</span>
              {underReview && (
                <span className="text-danger ms-2">
                  (since {underReviewDate})
                </span>
              )}
            </label>
          </div>
        </Col>
      </Row>
      {underReview && (
        <Row>
          <Col sm={12}>
            <Alert color="light" className="border border-danger">
              Merchant has been placed under further review, meaning that they
              are undergoing further scrutiny and have been requested for
              further details about their business. Their KYB status is not
              impacted by this.
            </Alert>
          </Col>
        </Row>
      )}
      {confirmAlert && (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="warning"
          confirmBtnText="Continue"
          cancelBtnBsStyle="secondary"
          onConfirm={() => {
            setConfirmAlert(false)
            handleChangeUnderAdditionalReview()
          }}
          onCancel={() => setConfirmAlert(false)}
        >
          Placing this merchant under further review may trigger automated
          emails being sent to them.
        </SweetAlert>
      )}
    </div>
  )
}

export default KYBReview
