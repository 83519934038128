import moment from 'moment'
import { useVideoRequestsQuery } from './hooks/video-requests.hook'
import { Label, Table } from 'reactstrap'
import React from 'react'

type SupportMerchantVideoRequestsProps = {
  merchantId: string
}

const SupportMerchantVideoRequests = (
  props: SupportMerchantVideoRequestsProps,
) => {
  const { merchantId } = props

  const query = useVideoRequestsQuery(merchantId)

  if (query.isLoading) {
    return <div>Loading video requests...</div>
  }

  if (query.isError || !query.data) {
    return <div>Error retrieving video requests. ${query.error}</div>
  }

  const videoRequests = query.data.data

  return (
    <>
      <Label>Video Requests</Label>
      <Table className="table table-striped">
        <thead>
          <tr>
            <th style={{ width: 150 }}>Created</th>
            <th style={{ width: 150 }}>ID</th>
            <th style={{ width: 150 }}>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {videoRequests.map((vr) => (
            <tr key={vr.id}>
              <td>{moment(new Date(vr.createdAt)).format('DD/MM/YY HH:mm')}</td>
              <td>{vr.id.slice(0, 8)}</td>
              <td>{vr.status}</td>
              <td>
                {vr.responseUrl ? (
                  <button
                    type="button"
                    className="btn btn-link p-0"
                    onClick={() => {
                      window.open(vr.responseUrl!, '_blank')?.focus()
                    }}
                  >
                    Link to response
                  </button>
                ) : (
                  '-'
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default SupportMerchantVideoRequests
