import { KYBSettingsDTO } from '../../dtos/kyb-settings.dto'
import { Alert, Col, Label, Row } from 'reactstrap'
import { useUpdateKYBSettingsMutation } from '../../hooks/kyb-settings.hook'
import React from 'react'

type KYBSettingToggles = Omit<
  KYBSettingsDTO,
  | 'kybStatus'
  | 'daily3DS2Limit'
  | 'additionalDocumentsRequestStatus'
  | 'underReview'
  | 'underReviewUpdatedAt'
>

type KYBSettingsProps = {
  merchantId: string
  kybSettings: KYBSettingToggles
  refetchFn: () => void
}

const KYBSettings = (props: KYBSettingsProps) => {
  const { merchantId, kybSettings, refetchFn } = props

  const mutation = useUpdateKYBSettingsMutation(merchantId, refetchFn)

  const toggleLabels: Record<keyof KYBSettingToggles, string> = {
    allowCardReaderOrder: 'Allow card reader orders',
    allowOnlinePayments: 'Allow online (ecommerce) payments',
    allowForeignOnlinePayments:
      'Allow online (ecommerce) payments from foreign cards',
    allowForeignCardPayments: 'Allow foreign card payments',
    allowTTPPayments: 'Allow Tap to Pay payments',
    allowInstantPayouts: 'Allow instant payouts',
    allowVoucherPurchases: 'Allow voucher purchases',
    allowFlaggedCountryTTPPayments:
      'Allow "flagged" card country of issue Tap to Pay payments',
    enforce3DS2: 'Enforce use of 3DS2, giving Lopay chargeback protection',
    allowMotoPayments: 'Allow MOTO payments',
  }

  const handleOnChange = (key: keyof KYBSettingToggles) => {
    mutation.mutate({ [key]: !kybSettings[key] })
  }

  return (
    <div>
      <Label>Settings and flags</Label>
      <Row>
        <Col sm={12}>
          {Object.entries(kybSettings)
            .filter(([key]) => key in toggleLabels)
            .map(([key, value]) => (
              <div className="form-check mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={key}
                  checked={value}
                  onChange={(e) =>
                    handleOnChange(key as keyof KYBSettingToggles)
                  }
                  disabled={mutation.isLoading}
                />
                <label className="form-check-label" htmlFor={key}>
                  {toggleLabels[key as keyof KYBSettingToggles]}
                  {mutation.isLoading ? (
                    <small className="text-primary ms-2">Saving...</small>
                  ) : (
                    ''
                  )}
                </label>
              </div>
            ))}
        </Col>
        <Col className="mt-4">
          <Alert color="info">
            Keeping <b>3DS2 enabled</b> helps us to shift the liability for
            disputes away from us, by ensuring that we request customer 2FA
            validation where possible. This is important in helping to prevent
            us losing revenue from the actions of malicious actors.
          </Alert>
        </Col>
      </Row>
    </div>
  )
}

export default KYBSettings
