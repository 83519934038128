import { useMutation, useQuery } from 'react-query'
import {
  changeAdditionalDocumentsStatus,
  getKYBStatusAndSettings,
  updateKYBSettings,
  updateKYBStatus,
  updateReviewStatus,
} from '../apis/kyb-settings.api'
import { KYBSettingsDTO } from '../dtos/kyb-settings.dto'
import { KYBStatus } from '../../../FraudPrevention/kyb-threshold.type'
import { AdditionalDocumentsRequestStatus } from '../enums/additional-documents-request-status.enum'

export function useKYBSettingsQuery(merchantId: string) {
  return useQuery({
    queryKey: ['merchantKYBSettings'],
    queryFn: () => getKYBStatusAndSettings(merchantId),
    refetchInterval: 10000,
  })
}

export function useUpdateKYBSettingsMutation(
  merchantId: string,
  refetchFn: () => void,
) {
  return useMutation(
    (updatedSettings: Partial<Omit<KYBSettingsDTO, 'kybStatus'>>) => {
      return updateKYBSettings(merchantId, updatedSettings)
    },
    {
      onSuccess: async () => refetchFn(),
    },
  )
}

export function useUpdateReviewStatusMutation(
  merchantId: string,
  refetchFn: () => void,
) {
  return useMutation(
    (underReview: boolean) => {
      return updateReviewStatus(merchantId, underReview)
    },
    {
      onSuccess: async () => refetchFn(),
    },
  )
}

export function useUpdateKYBStatusMutation(
  merchantId: string,
  refetchFn: (kybStatus?: KYBStatus) => void,
) {
  return useMutation(
    (payload: { kybStatus: KYBStatus; note?: string }) => {
      return updateKYBStatus(merchantId, payload)
    },
    {
      onSuccess: async (data, variables) => {
        refetchFn(variables.kybStatus)
      },
    },
  )
}

export function useChangeAdditionDocumentsStatus(
  merchantId: string,
  refetchFn: () => void,
) {
  return useMutation(
    (newStatus: AdditionalDocumentsRequestStatus) => {
      return changeAdditionalDocumentsStatus(merchantId, newStatus)
    },
    {
      onSuccess: async () => refetchFn(),
    },
  )
}
